export default [
    {
        title: "Dashboard",
        route: "home",
        icon: "HomeIcon",
        permission: 1,
    },
    {
        header: ' Configuraciones',
        permission: 2,
    },
    {
        title: "Configuraciones ",
        icon: "SettingsIcon",
        permission: 2,
        children: [
            {
                title: "Configuraciones",
                route: "settings-app",
                icon: 'SettingsIcon',
                permission: 3,        
            },
            {
                title: "Relaciones",
                route: "settings-relation-articles",
                icon: 'LinkIcon',
                permission: 4,
                children: [
                    {
                        title: "Artículos",
                        route: "settings-relation-articles",
                        icon: 'CircleIcon',
                        permission: 5,        
                    },
                    {
                        title: "Lineas Artículos",
                        route: "settings-relation-lines",
                        icon: 'CircleIcon',
                        permission: 6,
                    },
                    {
                        title: "Precios",
                        route: "settings-relation-prices",
                        icon: 'CircleIcon',
                        permission: 7,        
                    },
                ]        
            },

          
            {
                title: "Roles",
                route: "settings-roles",
                icon: 'CircleIcon',
                permission: 8,        
            },
			{
                title: "Permisos",
                icon: 'KeyIcon',
                route: "settings-permissions",
                permission: 9,
            },	
            {
                title: "Usuarios",
                icon: 'UsersIcon',
                route: "settings-users",
                permission: 10,
            },		
		]
	},
    {
        title: "Catálogos",
        icon: "GridIcon",
        permission: 11,
        children: [
            {
                title: "Operadores",
                icon: 'UsersIcon',
                route: "apps-operators",
                permission: 12,
            },
            {
                title: "Camiones",
                icon: 'TruckIcon',
                route: "apps-trucks",
                permission: 13,
            },
        ]
    },    
    {
        header: 'Aplicaciones ',
        permission: 15,
    },
    {
        title: "Aplicaciones",
        icon: "BoxIcon",
        permission: 15,
        children: [

            {
                title: "Inventarios",                
                icon: "CircleIcon",
                permission: 16,
                children: [
                    {
                        title: "Ingresos Provisionales",
                        route: "apps-inventories-provisional-income",
                        icon: "CircleIcon",
                        permission: 17,
                    },        
                ]
            },

            {
                title: "Compras",
                route: "apps-purchase",
                icon: "ShoppingCartIcon",
                permission: 14,
            },
            {
                title: "Créditos",
                route: "apps-customers-credit-limits",
                icon: 'CircleIcon',
                permission: 18,
            },

            {
                title: "Vales de Traspasos",
                route: "apps-inventories-transfer-vouchers",
                icon: 'FileIcon',
                permission: 19,
            },

            {
                title: "Enrutamiento",
                route: "apps-routings",
                icon: 'ShuffleIcon',
                permission: 20,
            },
            {
                title: "Logística",
                route: "app-logistics",
                icon: 'ShoppingCartIcon',
                permission: 21,
            },
            {
                title: "Salidas",
                route: "apps-routings-outputs",
                icon: 'ShuffleIcon',
                permission: 22,
            },
        ],
    },
    {
        title: "Reportes",
        icon: "PieChartIcon",
        permission: 23,
        children: [
            {
                title: "Cambio de Precios",
                route: "apps-reports-price-changes",
                icon: 'BarChart2Icon',
                permission: 24,
            },
            {
                title: "Lista de Precios",
                route: "apps-reports-price-list",
                icon: 'BarChart2Icon',
                permission: 25,
            },
            {
                title: "Límites de Créditos",
                route: "apps-reports-credit-limits",
                icon: 'BarChart2Icon',
                permission: 26,
            },
            {
                title: "Bitácora de Enrutamiento",
                route: "apps-reports/routing-logs",
                icon: 'BarChart2Icon',
                permission: 27,
            },
            {
                title: "Traspasos Almacenes",
                route: "apps-reports/transfers-list",
                icon: 'BarChart2Icon',
                permission: 28,
            },
            {
                header: "Rutas",
                title: "Rutas",
                icon: "BarChart2Icon",
                permission: 29,
                children: [
                    {
                        title: "Salidas App Vs Carrito",
                        route: "apps-reports/routing-app-carrito",
                        icon: 'BarChart2Icon',
                        permission: 30,
                    },
                    {
                        title: "Reporte de Rutas",
                        route: "apps-reports/routings",
                        icon: 'BarChart2Icon',
                        permission: 31,
                    },
                    {
                        title: "Entregas Por Chofer",
                        route: "apps-reports/delivery-driver",
                        icon: 'BarChart2Icon',
                        permission: 32,
                    },
                    {
                        title: "Artículos Por Entregar",
                        route: "apps-reports/articles-delivered",
                        icon: 'BarChart2Icon',
                        permission: 33,
                    },
                ] 
            }
        ]
    },
];
