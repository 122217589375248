<template>
	<b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">

		<!-- Content Left -->
		<b-col class="content-header-left mb-2" cols="12" md="9">
			<b-row class="breadcrumbs-top">
				<b-col cols="12">
					<h2 class="content-header-title float-left pr-1 mb-0">
						{{ $route.meta.pageTitle }}
					</h2>
					<div class="breadcrumb-wrapper">
						<b-breadcrumb>
							<b-breadcrumb-item>
								<feather-icon icon="DatabaseIcon" size="16" class="align-text-top" />
							</b-breadcrumb-item>
							<b-breadcrumb-item v-b-tooltip.hover.top="`Conectado en ${systemConexion.dataSource}`">
								{{ systemConexion.nombre || '--' }}
							</b-breadcrumb-item>
							<b-breadcrumb-item>
								<feather-icon icon="GitBranchIcon" size="16" class="align-text-top" />
								{{ systemBranchName.nombre || '--' }}
							</b-breadcrumb-item>
						</b-breadcrumb>

					</div>
				</b-col>
			</b-row>
		</b-col>

		<!-- Content Right -->
		<b-col class="content-header-right text-md-right d-md-block _d-none mb-1" md="3" cols="12">
			<div v-if="isViewPurchase">
				<span v-if="isViewPurchase && lastDatePurchase" class="mr-1">
					<small ref="lastUpdatePurchase"><strong>Últ. Actualización: {{ lastDatePurchase !== '0000-00-00 00:00:00' ? formatDate(lastDatePurchase) : lastDatePurchase }}</strong></small>
				</span>

				<b-dropdown v-if="isViewPurchase" variant="link" no-caret toggle-class="p-0" right>

					<template #button-content>
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon">
							<feather-icon icon="SettingsIcon" />
						</b-button>
					</template>

					<b-dropdown-item @click="isActivePurchazeCustomize = true">
						<feather-icon icon="CheckSquareIcon" size="16" />
						<small> Configurar</small><br>
						Columnas Compras
					</b-dropdown-item>

					<b-dropdown-item v-if="warehouseId && isCanRecalculateRotations" @click="calculateRotations">
						<span class="text-warning">
							<feather-icon icon="RepeatIcon" size="16" />
							Calcular Rotaciones
						</span>
					</b-dropdown-item>
				</b-dropdown>
			</div>
			
			<!-- <div v-if="isViewHomeAndIsSeller">
				<b-dropdown v-model="filterBy" variant="link" no-caret toggle-class="p-0 mr-1" right>
					<template #button-content>
						<b-button  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon">
							<feather-icon icon="CalendarIcon" />
							{{ filterBy ? filterBy.label : 'Filtro Por' }}
						</b-button>
					</template>

					<b-dropdown-item v-for="(option, index) in filterOptions" :key="option.key + index" @click="changeFilterOnDashboardSeller(option)"
					class="text-capitalize">
						{{ option.label }}
					</b-dropdown-item>
				</b-dropdown>	
				
				<span>
					<b-button @click="refreshDashboard"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon">
						<feather-icon icon="RefreshCwIcon" />
						Actualizar
					</b-button>
				</span>
			</div> -->
		</b-col>


		<purchase-customize :is-active="isActivePurchazeCustomize" @close="isActivePurchazeCustomize = false" />
		<calculation-parameters :is-active="isActiveCalculateParameters" @close="isActiveCalculateParameters = false" />
	</b-row>
</template>

<script>
import {
	BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton, BAlert, BLink,
	BTooltip, VBTooltip, BFormInput,
} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, onErrorCaptured, nextTick } from "@vue/composition-api";
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import * as helper from '@/libs/helpers';
import moment from 'moment'
import vSelect from 'vue-select'

import PurchaseCustomize from '@core/components/purchase-customizer/PurchaseCustomize.vue';
import CalculationParameters from '@core/components/purchase-customizer/CalculationParameters.vue';

import CentralJmrsRotacionesArtsProvider from '@/providers/CentralJmrsRotacionesArts'
const CentralJmrsRotacionesArtsResource = new CentralJmrsRotacionesArtsProvider()

import SellersProvider from '@/providers/Sellers'
const SellersResource = new SellersProvider()

export default {
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},
	components: {
		BBreadcrumb,
		BBreadcrumbItem,
		BRow,
		BCol,
		BDropdown,
		BDropdownItem,
		BButton,
		BAlert,
		BLink,
		BTooltip, 
		VBTooltip,
		BFormInput,

		//
		vSelect,
		PurchaseCustomize,
		CalculationParameters,
	},
	computed: {
		lastDatePurchase() {
			return store.getters['auth/getLastUpdatePurchase']
		},
		isRecalculateRotatios() {
			return store.getters['auth/getIsRecalculateRotations']
		},
		isCanRecalculateRotations() { // 36 = Calcular Rotaciones
			return this.canPermissions(36) && this.isControlTotal
		},
		isControlTotal() {
			return store.getters['auth/getConexion']?.control_Total || false;
		}

	},

	setup() {

		const isActivePurchazeCustomize = ref(false)
		const isActiveCalculateParameters = ref(false)
		const sellers = ref([])
		const searchQuery = ref('')
		const vendedor_Id = ref('')

		const systemConexion = store.getters['auth/getConexion']
		const systemWarehouseName = store.getters['auth/getWarehouse']
		const systemBranchName = store.getters['auth/getBranch']

		// Obtener la fecha actual
		const currentDate = moment();

		// Mes actual
		const currentMonth = currentDate.format('MMMM'); // Nombre del mes actual
		const currentYear = currentDate.format('YYYY'); // Año en curso

		// Mes anterior
		const previousMonthDate = currentDate.clone().subtract(1, 'month');
		const previousMonth = previousMonthDate.format('MMMM'); // Nombre del mes anterior
		const previousMonthYear = previousMonthDate.format('YYYY'); // Año del mes anterior

		// Mes actual del año anterior
		const currentMonthLastYear = currentDate.clone().subtract(1, 'year');
		const currentMonthLastYearName = currentMonthLastYear.format('MMMM'); // Mes actual, pero del año pasado
		const lastYear = currentMonthLastYear.format('YYYY'); // Año anterior

		// Mes anterior del año pasado
		const previousMonthLastYear = previousMonthDate.clone().subtract(1, 'year');
		const previousMonthLastYearName = previousMonthLastYear.format('MMMM'); // Mes anterior, pero del año pasado
		const previousYear = previousMonthLastYear.format('YYYY'); // Año anterior para el mes anterior

		const filterOptions = ref([
			{ key: 'current_month', label: `${currentMonth} ${currentYear}` },
			{ key: 'previous_month', label: `${previousMonth} ${currentYear}` },
			{ key: 'current_month_last_year', label: `${currentMonthLastYearName} ${lastYear}` },
			{ key: 'previous_month_last_year', label: `${previousMonthLastYearName} ${previousYear}` },
		])

		const filterSellers = computed(() => {
			return sellers.value.filter(seller =>
				seller.nombre.toLowerCase().includes(searchQuery.value.toLowerCase())
			);
		})
		
		const filterBy = computed(() => {
			return store.getters['auth/getFilterOnDashboardSeller']
		})

		const isViewPurchase = computed(() => {
			return store.getters['auth/getCurrentRoute'] === 'apps-purchase'
		})

		const isViewHomeAndIsSeller = computed(() => {
			return store.getters['auth/getCurrentRoute'] === 'home' &&
			( (store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleSeller'] : false))
		})	
		
		const isSeller = computed(() => {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleSeller'] : false
		})

		const warehouseId = computed(() => {
			return store.getters['auth/getWarehouseId']
		})

		const selectedSeller = (option) => {
			option.key = filterBy.value.key
			option.label = filterBy.value.label
			store.dispatch('auth/UPDATE_FILTER_ON_DASHBOARD_SELLER', option)
		}

		const calculateRotations = async () => {

			store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', false)
			const payload = {
				idSucursal: store.getters['auth/getBranch'].sucursal_Id,
				idAlmacen: store.getters['auth/getWarehouseId'],
				rolClaveArtId: 17,
			}

			try {
				store.dispatch('auth/UPDATE_IS_RECALCULATE_ROTATIONS', true)
				const { data } = await CentralJmrsRotacionesArtsResource.CalculateRotations(payload)
				console.log(data)
				if (data.isSuccesful) {
					if (data.data) {
						store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', true)
						store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', data.data.fecha_Actualizacion)
					}
				} else {
					store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', true)
					store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', false)
					store.dispatch('auth/UPDATE_LAST_UPDATE_PURCHASE', '0000-00-00 00:00:00')
				}
			} catch (e) {
				console.error(e)
				store.dispatch('auth/UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION', true)
				store.dispatch('auth/UDPDATE_IS_RECALCULATE_RATION_END', false)
				store.dispatch('auth/UPDATE_IS_RECALCULATE_ROTATIONS', false)
				helper.handleResponseErrors(error)
			}
		}

		const canPermissions = (id) => {
			if (id == 0) return true //only dashboard
			const permission = store.getters['auth/getPermissions']
			return _.find(permission, (p) => p.permiso_Id === id) ? true : false
		}

		const refreshDashboard = () => {
			store.commit('auth/SET_IS_REFRESH_DASHBOARD', false)
			nextTick(() => {
				store.commit('auth/SET_IS_REFRESH_DASHBOARD', true)
			})
		}

		const changeFilterOnDashboardSeller = (option) => {
			// option.vendedor_Id = isSeller ? vendedor_Id.value : store.getters['auth/getUser'].id
			store.dispatch('auth/UPDATE_FILTER_ON_DASHBOARD_SELLER', option)
		}

		const getSellers = async () => {			
			const checkSellers = store.getters['auth/getSellers']
			if (checkSellers.length <= 0) {
				const { data } = await SellersResource.getAll()
				sellers.value = data.data
				store.dispatch('auth/UPDATE_SELLERS', sellers.value)
			} else {
				sellers.value = checkSellers
			}
		}

		onMounted( async () => {
			const filterOnDashboardSeller = store.getters['auth/getFilterOnDashboardSeller']
			if(!filterOnDashboardSeller || !filterOnDashboardSeller.key) {
				store.dispatch('auth/UPDATE_FILTER_ON_DASHBOARD_SELLER', filterOptions[0])
			}
		})

		return {
			systemConexion,
			systemWarehouseName,
			systemBranchName,
			isActivePurchazeCustomize,
			isActiveCalculateParameters,
			filterBy,
			filterOptions,
			sellers,
			searchQuery,
			vendedor_Id,

			//
			filterSellers,
			warehouseId,
			isViewPurchase,
			isViewHomeAndIsSeller,
			refreshDashboard,
			selectedSeller,
			changeFilterOnDashboardSeller,
			calculateRotations,
			canPermissions,
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.scrolled-dropdown {
  max-height: 200px; /* Establece la altura máxima del dropdown */
  overflow-y: auto;  /* Habilita el scroll vertical */
}
</style>